import React, { useContext } from "react"
import { Form, Formik } from "formik"
import * as Yup from "yup"

import MedicineBox from "elements/MedicineBox"
import RefillSearch from "../../../../../Search/RefillSearch"
import FormInput from "../../../../../Elements/Form/FormInput"
import AddMoreMedicinesButton from "./AddMoreMedicinesButton"

import { RefillsContext } from "../../../RefillsContext/RefillsContext"

const AddMedicinesSection = ({
  showSearch,
  setShowSearch,
  medensureFormulary,
}) => {
  const { refillsState, refillsDispatch } = useContext(RefillsContext)

  const handleSubmit = (values) => {
    let medicine = values.medicine
    const medicineList = [...refillsState.updatedPrescription.medicines]
    if (!medicine.molecule)
      medicineList.push({ ...medicine, dailyIntake: values.dailyIntake })
    else medicineList.push({ ...medicine, dailyIntake: values.dailyIntake })
    refillsDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...refillsState,
        updatedPrescription: {
          ...refillsState.updatedPrescription,
          medicines: medicineList,
        },
      },
    })
    setShowSearch(false)
  }

  let selectedMedicines = refillsState.updatedPrescription.medicines || []
  selectedMedicines = selectedMedicines.map((medicine) => {
    return medicine.drugName
  })

  const searchComponentProps = {
    placeholder: "Metformin",
    dataSource: {
      type: "json",
      data: medensureFormulary.filter(
        (medicine) => !selectedMedicines.includes(medicine.drugName)
      ),
      lookupKeys: ["molecule", "drugName"],
    },
    message: null,
  }

  switch (true) {
    case !!showSearch:
      return (
        <MedicineBox
          handleDelete={
            refillsState?.updatedPrescription?.medicines?.length === 0
              ? null
              : () => setShowSearch(false)
          }
        >
          <Formik
            initialValues={{ medicine: null, dailyIntake: "" }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              dailyIntake: Yup.number()
                .required("This field is required")
                .min(0.01, "Daily intake must be more than 0")
                .test(
                  "dailyIntake",
                  "Daily intake must be in increments of 0.25",
                  (value) => {
                    const stringValue = value.toString()
                    if (stringValue.includes(".")) {
                      const decimalPart = parseInt(stringValue.split(".")[1])
                      if (stringValue.split(".")[1].length === 1) {
                        return (decimalPart * 10) % 25 === 0
                      }
                      return decimalPart % 25 === 0
                    }
                    return true
                  }
                ),
            })}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <RefillSearch
                  searchType="drug"
                  label="Search Medicines"
                  withIcon={true}
                  {...searchComponentProps}
                  onSelect={(item) => setFieldValue("medicine", item)}
                  value={values?.medicine?.drugName}
                />
                <FormInput
                  name="dailyIntake"
                  label="Daily Intake"
                  type="text"
                  maxLength="5"
                  hasAddons
                  onKeyPress={(event) => {
                    if (!/^[0-9.]$/.test(event.key)) event.preventDefault()

                    // prevent addition of another decimal point when one already exists
                    if (
                      event?.target?.value?.includes(".") &&
                      event.key === "."
                    )
                      event.preventDefault()

                    const wholeNumber = parseInt(
                      event?.target?.value?.split(".")?.[0]
                    )

                    if (isNaN(wholeNumber) && event.key === ".")
                      event.preventDefault()

                    if (
                      wholeNumber === 0 &&
                      !event.target.value?.includes(".") &&
                      event.key !== "."
                    )
                      event.preventDefault()

                    const decimals = event?.target?.value
                      ?.split(".")?.[1]
                      ?.toString()

                    if (wholeNumber === 10) {
                      event.preventDefault()
                      setFieldValue("dailyIntake", "10")
                    }

                    if (decimals?.length >= 2) event.preventDefault()
                  }}
                  onChange={(event) => {
                    if (event.target.value > 10) {
                      setFieldValue("dailyIntake", "10")
                    }
                  }}
                  addonRight={
                    <button className="button is-static">per day</button>
                  }
                  isRequired
                />
                <button
                  className="button is-secondary is-fullwidth"
                  type="submit"
                  disabled={
                    !values.dailyIntake ||
                    !values?.medicine?.drugName ||
                    parseFloat(values?.dailyIntake) === 0
                  }
                >
                  Add Medicine
                </button>
              </Form>
            )}
          </Formik>
        </MedicineBox>
      )
    case !showSearch &&
      refillsState?.updatedPrescription?.medicines?.length > 0:
      return (
        <AddMoreMedicinesButton
          handleClick={() => {
            setShowSearch(true)
          }}
        />
      )
    default:
      return null
  }
}

export default AddMedicinesSection
